import React from "react";
import styled from "styled-components";

import ProjectThumbnail from "../molecules/project-thumbnail";
import { device } from "../utils/media-queries";
import {
  CASPERBLOCKCHAIN_LINK,
  CASPERTIGERS_LINK,
  CASPER_PROMO_LINK,
  DEFDONE_LINK,
  CHAINLENDAR_LINK,
  ZENHUB_LINK,
  FONDANT_LINK,
} from "../config";

import chainlendarImg from "../images/chainlendar-banner.png";
import CasperTigersImg from "../images/caspertigers-thumbnail.svg";
import CasperblockchainImg from "../images/casperecosystem-banner.png";
import ZenhubImg from "../images/zenhub-thumbnail.svg";
import CasperpromoImg from "../images/casperpromo-thumbnail.svg";
import Web3eLenrning from "../images/web3elerning-thumbnail.svg";
import HybridNftImg from "../images/hybridnft-thumbnail.svg";
import DefdoneImg from "../images/defdone-thumbnail.svg";
import FondantImg from "../images/fondant-thumbnail.svg";
import FSImg from "../images/fs-thumbnail.svg";
import Placeholder from "../images/project-thumbnail-placeholder.svg";

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.black};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1020px;
  min-height: 550px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: 160px 100px;
  overflow-x: hidden;
  gap: 32px 20px;

  @media ${device.laptop} {
    padding: 48px 40px;
  }

  @media ${device.mobile} {
    padding: 48px 20px;
    gap: 32px 15px;
  }
`;

const ProjectsList = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <ProjectThumbnail
          external
          pageurl={CHAINLENDAR_LINK}
          title={"Chainlendar.io"}
          desc={"The first blockchain advent calendar"}
          image={chainlendarImg}
        />
        <ProjectThumbnail
          external
          pageurl={CASPERTIGERS_LINK}
          title={"CasperTigers.io"}
          desc={"Unique NFT platform for the Casper Blockchain"}
          image={CasperTigersImg}
        />
        <ProjectThumbnail
          external
          pageurl={CASPERBLOCKCHAIN_LINK}
          title={"Casperecosystem.io"}
          desc={"Ecosystem explorer for the Casper Blockchain"}
          image={CasperblockchainImg}
        />
        <ProjectThumbnail
          external
          pageurl={DEFDONE_LINK}
          title={"Defdone home page"}
          desc={"Prepared exclusively for our outsourcing partner."}
          image={DefdoneImg}
        />
        <ProjectThumbnail
          pageurl={"/projects/friendlysoccer"}
          title={"Friendly Soccer"}
          desc={"The first web3 game on the Casper blockchain"}
          image={FSImg}
        />
        <ProjectThumbnail
          external
          pageurl={CASPER_PROMO_LINK}
          title={"Casper promo animation"}
          desc={
            "Prepared to promote the project on the World Economic Forum in Davos, Switzerland."
          }
          image={CasperpromoImg}
        />
        <ProjectThumbnail
          external
          pageurl={FONDANT_LINK}
          title={"Fondant"}
          desc={"Your Casper Blockchain Testing Framework"}
          image={FondantImg}
        />
        <ProjectThumbnail
          pageurl={ZENHUB_LINK}
          external
          // iscomingsoon
          title={"Zenhub Advanced Dashboard"}
          desc={"Specialized toolset for managing complex projects in Zenhub"}
          image={ZenhubImg}
        />
        <ProjectThumbnail
          pageurl={""}
          iscomingsoon
          title={"Web3 elearning platform"}
          desc={"Coming soon!"}
          image={Web3eLenrning}
        />
        <ProjectThumbnail
          pageurl={""}
          iscomingsoon
          title={"Hybrid NFT platform"}
          desc={"Mixing physical objects with NFT certificates. Coming soon!"}
          image={HybridNftImg}
        />
        <ProjectThumbnail
          pageurl={""}
          iscomingsoon
          title={"Web3 social platform"}
          desc={"Coming soon!"}
          image={Placeholder}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ProjectsList;
